define("discourse/plugins/user-whispers/discourse/initializers/user-whispers", ["exports", "discourse/controllers/composer", "discourse/models/composer", "discourse-common/utils/decorators", "discourse-common/lib/icon-library"], function (_exports, _composer, _composer2, _decorators, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "user-whispers",
    initialize(container) {
      var _dec, _dec2, _dec3, _obj;
      _composer.default.reopen((_dec = (0, _decorators.default)("whisperer", "model.action", "model.post.admin", "model.post"), _dec2 = (0, _decorators.observes)("isStaffUser", "model.post.admin", "isWhispering", "model.post"), _dec3 = (0, _decorators.observes)("showPreview", "isWhispering", "model.post"), (_obj = {
        canWhisper(whisperer, modelAction, isAdmin) {
          // admin is null whenever there is no post, i.e. when there is no reply, i.e. when it's a new topic
          return this.siteSettings.user_whispers_enabled && modelAction === _composer2.default.REPLY && (whisperer || isAdmin == null || isAdmin) || this._super(...arguments);
        },
        _setDangerClass() {
          if (!this.get("isWhispering")) return this._removeDangerClass();
          if (!this.siteSettings.user_whispers_enabled) return this._removeDangerClass();
          if (!this.get("isStaffUser")) return this._removeDangerClass();

          // if we are replying and the post we're replying to was not made by an admin
          const isAdmin = this.get("model.post.admin");
          if (isAdmin != null && !isAdmin) {
            return this._addDangerClass();
          }
        },
        _addDangerClass() {
          Ember.run.next(this, () => {
            $("#reply-control").addClass("user-whisper-danger");
          });
        },
        _removeDangerClass() {
          Ember.run.next(this, () => {
            $("#reply-control").removeClass("user-whisper-danger");
          });
        },
        _addWhisperIcon() {
          if (this.get("showPreview") && this.get("isWhispering") && $("#reply-control .user-whisper").length < 1) {
            const icon = (0, _iconLibrary.iconHTML)("far-eye-slash", {
              class: "user-whisper"
            });
            Ember.run.next(this, () => {
              $(".d-editor-preview").prepend(icon);
            });
          } else {
            $(".d-editor-preview .user-whisper").remove();
          }
        }
      }, (_applyDecoratedDescriptor(_obj, "canWhisper", [_dec], Object.getOwnPropertyDescriptor(_obj, "canWhisper"), _obj), _applyDecoratedDescriptor(_obj, "_setDangerClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_setDangerClass"), _obj), _applyDecoratedDescriptor(_obj, "_addWhisperIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_addWhisperIcon"), _obj)), _obj)));
    }
  };
});